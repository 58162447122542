import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import LogoTitle from '../../assets/images/A.png';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import Loader from 'react-loaders';
import Sub from './Sub';
import Water from './Water';


const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = "yush ".split("")
    const jobArray = "Software Developer.".split("")

    useEffect(() => {
            setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
    }, [])

    return (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>e</span>
                <span className={`${letterClass} _13`}>y,</span>
                <br /> 
                <span className={`${letterClass} _14`}>I</span>
                <span className={`${letterClass} _15`}>'m</span>
                <img className='A' src={LogoTitle} alt="developer" />
                <AnimatedLetters letterClass={letterClass}
                strArray={nameArray}
                idx={15} />
                <br />
                <AnimatedLetters letterClass={letterClass}
                strArray={jobArray}
                idx={22} />
                </h1>
                <h2>Full-stack / Web-Development</h2>
                <Link to="/contact" className='flat-button'>
                    Contact Me
                </Link>
            </div>
            <Sub />
            <Water />
        </div>
        <Loader type="pacman" />
        </>
    )
}

export default Home