import React, { useState, useEffect } from 'react'
import  Loader from "react-loaders"
import './index.scss'
import AnimatedLetters from './../AnimatedLetters/index'
import portfolioData from '../../data/portfolio.json'
import Water from '../Home/Water'
import TextShpere from './Skills/index';

const Portfolio = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover')
        },3000)

        return () => {
            clearTimeout(timer)
        }
    })

    const renderPortfolio = (portfolio) => {
        return (
            <div className='image-container'>
                {
                    portfolio.map((port, idx) =>{
                        return(
                            <div className = "image-box" key = {idx}>
                                <img src={port.cover}
                                className="portfolio-image"
                                alt="portfolio"/>
                                <div className="content" >
                                    <p className="title">{port.title}</p>
                                    <h4 className="desc">{port.description}</h4>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    return (
        <>
            <div className="container portfolio-page">
                <h1 className="page-title">
                    <AnimatedLetters 
                        letterClass={letterClass}
                        strArray={"Portfolio".split("")}    
                        idx={15}
                    />
                </h1>
                <div>{renderPortfolio(portfolioData.portfolio)}</div>
                <TextShpere />
            </div>
            <Water />
            <Loader type="pacman"/>
        </>
    )
}

export default Portfolio