import React, { useEffect } from "react";
import "./index.scss";

import TagCloud from "TagCloud";

const TextShpere = () => {
  // Animation settings for Text Cloud
  useEffect(() => {
    const container = '.tagcloud';
    const texts = [
      'Python',
      'HTML',
      'CSS',
      'C',
      'PHP',
      'Java',
      'JavaScript',
      'SQL',
      'MySQL',
      'MongoDB',
      'WordPress',
      'React',
      'NodeJS',
      'VSCode',
      'Jira',
      'Express',
      'Shopify',
      'Jquery',
      'GIT',
      'GITHUB',
    ];

    const options = {
      radius: 500 ,
      maxSpeed: 'fast',
      initSpeed: 'fast',
      keep: true,
    };

    TagCloud(container, texts, options);
  }, []);

  return (
    <>
      <div className="text-shpere">
        {/* span tag className must be "tagcloud"  */}
        <span className="tagcloud"></span>
      </div>
    </>
  );
};

export default TextShpere;