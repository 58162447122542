import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import Water from '../Home/Water'

const Contact = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const refForm = useRef()

    useEffect(() => {
        setTimeout(() => {
        setLetterClass('text-animate-hover')
    }, 3000)
    }, [])

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_zpzs8gr',
                'template_22ih2wg',
                refForm.current,
                '0qfyOuntUdnvFoaSJ'
            )
            .then(
                () => {
                    alert('Message has been sent!')
                    window.location.reload(false)
                },
                () => {
                    alert('Message send failed, try again')
                }
            )
    }
    
    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters 
                        letterClass={letterClass}
                        strArray={"Contact Me".split("")}
                        idx={15}
                        />
                    </h1>
                    <p>
                    If you're looking for an enthusiastic and driven intern to join your team, I'd love to hear from you! 
                    I'm passionate about software development, especially in the areas of full-stack or web development.
                    With prior experience working on development teams, I'm comfortable collaborating and tackling new challenges.
                    Feel free to contact me using the form below to learn more about my skills and qualifications. 
                    Thank you for your consideration, and I look forward to hearing from you!
                    </p>
                    <div className="contact-form">
                    <form ref={refForm} onSubmit={sendEmail}>
                    <ul>
                        <li className="half">
                        <input placeholder="Name" type="text" name="name" required />
                        </li>
                        <li className="half">
                        <input
                            placeholder="Email"
                            type="email"
                            name="email"
                            required
                        />
                        </li>
                        <li>
                        <input
                            placeholder="Subject"
                            type="text"
                            name="subject"
                            required
                        />
                        </li>
                        <li>
                        <textarea
                            placeholder="Message"
                            name="message"
                            required
                        ></textarea>
                        </li>
                        <li>
                        <input type="submit" className="flat-button" value="SEND" />
                        </li>
                    </ul>
                    </form>
                </div>
                </div>
                <div className='info-map'>
                    Ayush Byju,
                    <br />
                    Canada
                    <br />
                    Mississauga, Ontario <br />
                    <span>ayushbyju1@outlook.com</span>
                </div>
                <div className='map-wrap'>
                    <MapContainer center={[43.595310, -79.640579]} zoom={10} scrollWheelZoom={false}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        <Marker position={[43.595310, -79.640579]}>
                        <Popup>
                            I live here, im always down for a chat or some coffee!
                        </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
            <Water />
            <Loader type='pacman' />
        </>
    )
}

export default Contact