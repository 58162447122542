import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCss3, faGitAlt, faHtml5, faJsSquare, faNode, faReact } from '@fortawesome/free-brands-svg-icons'
import Water from '../Home/Water'

const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
        setLetterClass('text-animate-hover')
    }, 3000)
    }, [])

    return (
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1 >
                    <AnimatedLetters
                    letterClass={letterClass}
                    strArray={"About Me".split("")}
                    idx={15}
                    />
                </h1>
                <p>
                    I'm an aspiring third-year computer science student with experience as a software developer.
                    where I contributed to solving bugs from the backlog as well as adding key features to the company's web service.
                </p>
                <p>
                    My greatest strength is my ability to adapt to a high-stress and fast-paced working environment. 
                    This, paired with my interpersonal and communication skills, makes me an asset when joining a team.
                </p>
                <p>
                    On a more personal note, I enjoy working out, learning new skills, and playing soccer. 
                    What I’m looking for is a company I can add value to and produce a positive return on investment for, 
                    where I can join a strong team that allows me to grow as a developer.
                </p>
            </div>

            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon ={faNode} color="#DD0031" />
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon ={faHtml5} color="#F06529" />
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon ={faCss3} color="#28A4d9" />
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon ={faReact} color="#5ED4F4" />
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon ={faJsSquare} color="#EFD81D" />
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon ={faGitAlt} color="#EC4D28" />
                    </div>
                </div>
            </div>
        </div>
        <Water />
        <Loader type="pacman" />
        </>
    )
}

export default About